import React, { ReactElement, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  AppBar,
  Box,
  CircularProgress,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Snackbar,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Alert from "@material-ui/lab/Alert";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarRoot: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(1, 1, 1, 4),
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

interface OpenWeatherI {
  lat: number;
  lon: number;
  hourly: { humidity: number; temp: number }[];
  current: {};
}

const MyGreatPlace = (props: {
  lat: number;
  lng: number;
  pin: OpenWeatherI;
  setPoint: (point: OpenWeatherI | null) => void;
}): ReactElement => {
  const { pin, setPoint } = props;
  const classes = useStyles();

  let firstDay = 0,
    secondDay = 0;

  pin?.hourly?.filter((d, i) => {
    if (d.humidity > 90 && d.temp >= 283.3 && d.temp <= 299.15) {
      if (i <= 24) firstDay = firstDay + 1;
      if (i > 24) secondDay = secondDay + 1;
    }
    return d;
  });

  // if (loading) {
  //   return <CircularProgress size={26} />;
  // }

  return (
    <RoomIcon
      className={classes.small}
      fontSize="small"
      style={{
        color: firstDay > 6 || secondDay > 6 ? "red" : "green",
      }}
      onClick={(e) => {
        e.stopPropagation();
        setPoint(pin);
      }}
    />
  );
};

export const Map = (): ReactElement => {
  const [open, setOpen] = React.useState<{ position: "left" | "right" } | null>(
    null
  );
  const [pins, setPins] = React.useState<OpenWeatherI[]>([]);
  const [point, setPoint] = useState<OpenWeatherI | null>(null);
  const [error, setError] = useState<boolean>(false);

  const classes = useStyles();

  const getData = async (
    lat: number,
    lon: number
  ): Promise<OpenWeatherI | null> => {
    const apiUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&cnt=16&appid=2197ece8dc4d8d0cc3b781afcff95e8a`;
    return await fetch(apiUrl)
      .then((res) => res.json())
      .then((data) => {
        if (!data.cod) {
          setError(false);
          return data;
        }
        setError(true);
        return null;
      });
  };

  useEffect(() => {
    const basePoints = [
      { lat: 42.70279699912083, lon: 25.384254910376153 },
      { lat: 41.82862687400524, lon: 24.222836609223187 },
      { lat: 42.29354917467665, lon: 23.558163757660687 },
      { lat: 42.06006861778317, lon: 24.478076121805945 },
      { lat: 42.5586, lon: 27.6417 },
      { lat: 41.5589, lon: 23.2744 },
    ];

    [{ lat: 41.5589, lon: 23.2744 }].forEach((bp) => {
      getData(bp.lat, bp.lon).then((d) => (d ? setPins([...pins, d]) : null));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (point) setOpen({ position: "right" });
  }, [point]);

  const handleDrawerOpen = () => {
    setOpen({ position: "left" });
  };

  const handleDrawerClose = () => {
    setOpen(null);
  };

  const defaultProps = {
    center: {
      lat: 42.617776492879806,
      lng: 25.178647156098187,
    },
    zoom: 8,
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Картофена мана
          </Typography>
          <Box className={classes.avatarRoot}>
            <RoomIcon
              className={classes.small}
              fontSize="small"
              style={{ color: "red" }}
            />
            <Typography variant={"caption"}>
              Висок риск от заболяване
            </Typography>
            <RoomIcon
              className={classes.small}
              fontSize="small"
              style={{ color: "green" }}
            />
            <Typography variant={"caption"}>
              Нисък риск от заболяване
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Snackbar
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={12000}
        onClose={() => setError(false)}
      >
        <Alert variant="filled" severity="warning">
          Данните в момента не са налични!
        </Alert>
      </Snackbar>
      <Drawer variant="persistent" anchor={open?.position} open={open !== null}>
        <div style={{ minWidth: "400px" }}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Divider />
          <div style={{ marginLeft: 24 }}>
            <pre style={{ fontSize: 11 }}>
              {JSON.stringify(point?.current, null, 4)}
            </pre>
          </div>
        </div>
      </Drawer>

      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDJr9GlSxVRUDj7R9rKvsZ8p-_eRq0hJHg" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onClick={(e) => {
            const lon = e.lng;
            getData(e.lat, lon).then((d) => (d ? setPins([...pins, d]) : null));
          }}
        >
          {pins.map((p) => (
            <MyGreatPlace
              key={p.lat + p.lon}
              lat={p.lat}
              lng={p.lon}
              pin={p}
              setPoint={setPoint}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
};
